import { Carousel } from 'components/slider/slider'
import { MenuNode, ThemeVariant } from 'types'
import { Box } from 'ui/core'
import { CategoryBox } from './categoryBox/categoryBox'
import { getSliderSettings } from './categorySliderSettings'

interface Props {
  categories: MenuNode[]
  theme: ThemeVariant
}

export const CategorySlider = ({ categories }: Props) => (
  <Box
    display="flex"
    justifyContent="stretch"
    alignItems="center"
    width="full"
    height="full"
    pt={1}
  >
    <Box
      width={['full', 'full', 'full', '10/12']}
      height="full"
      overflow="visible"
    >
      <Carousel<{ item: MenuNode }>
        getSliderSettings={(handleNext, handlePrev) =>
          getSliderSettings({}, handleNext, handlePrev)
        }
        items={categories.map((c) => ({ item: c }))}
        CardComponent={(props) => (
          <Box pr={1} py={[0, 0, 2]}>
            <CategoryBox {...props} />
          </Box>
        )}
      />
    </Box>
  </Box>
)
